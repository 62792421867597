import React, { FC } from 'react'
import { TWTCorePage, MainMenu } from '../twt-core'
import { Rows } from '../design-system'
import { fonts2020, theme2020 } from '../twt-2020/theme'
import { graphql } from 'gatsby'
import { CmsPageContext, TWT2020Theme, HomePageType } from '../cms'
import { LogoGraveyardElement } from '../cms/special-blocks'
import { PageFooter } from '../twt-core/organisms/page-footer'
import { themeColor } from '../twt-core/theme'
import { RegistrationScreen } from '../festival/organisms/registration'
import { EventGrid } from '../festival/organisms/event-list-block'
import { Heading } from '../design-system/atoms/text'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { LayoutCard } from '../twt-core/molecules/layout-card'
import { eventCardApolloFragments } from '../festival/molecules/event-card'

export const query = graphql`
  query RegisterQuery {
    wagtail {
      ...CmsSiteData
    }
  }
`

const RegisterPage = ({
  data: {
    wagtail: { logoGraveyard, ...site },
  },
}) => {
  return (
    <CmsPageContext.Provider
      value={{
        pageType: HomePageType,
        theme: TWT2020Theme,
        subpages: [],
      }}
    >
      <TWTCorePage
        theme={theme2020}
        fonts={fonts2020}
        titlePrefix="TWT20"
        title="Register for your Festival Pass"
      >
        <MainMenu menuItems={site.home.children} />
        <RegistrationScreen marginTop={3} size="large" />
        <Rows bg={themeColor.bgReverse} color={themeColor.reverseContent}>
          <LogoGraveyardElement collections={logoGraveyard} />
          <PageFooter menuItems={site.home.children} />
        </Rows>
      </TWTCorePage>
    </CmsPageContext.Provider>
  )
}

export default RegisterPage
